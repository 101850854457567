import ProductIdentifier from "@ingka/product-identifier"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import {
  DepartmentSalesLocation,
  SelfServeSalesLocation,
} from "@/types/responses/find"

interface SelfServeLocationProps {
  aisle: string
  bin: string
}

export const SelfServeLocation: React.FC<SelfServeLocationProps> = (props) => {
  const { t } = useTranslation()

  return (
    <div className={`flex flex-row gap-2 transition-all duration-150`}>
      <ProductIdentifier
        data-cy="product-aisle"
        value={props.aisle}
        label={t("SelfServeLocation-Aisle")}
        className="text-sm"
      />
      <ProductIdentifier
        data-cy="product-bin"
        value={props.bin}
        label={t("SelfServeLocation-Shelf")}
        className="text-sm"
      />
    </div>
  )
}

interface DepartmentLocationProps {
  department: string
  label: string
}

export const DepartmentLocation: React.FC<DepartmentLocationProps> = (
  props,
) => {
  return (
    <div className={`flex flex-row gap-2 transition-all duration-150`}>
      <ProductIdentifier
        value={props.department}
        label={props.label}
        className="text-sm"
      />
    </div>
  )
}

interface SalesLocationProps {
  location?: DepartmentSalesLocation | SelfServeSalesLocation
}

export const SalesLocation: React.FC<SalesLocationProps> = ({ location }) => {
  const { t } = useTranslation()

  return useMemo(() => {
    switch (location?.salesMethod) {
      case "SHOWROOM":
      case "MARKET_HALL":
        return (
          <div className="mt-0">
            <DepartmentLocation
              department={location.departmentName}
              label={t("common.location")}
            />
          </div>
        )
      case "SELF_SERVE":
        return (
          <div className="mt-0">
            <SelfServeLocation aisle={location.aisle} bin={location.bin} />
          </div>
        )
      default:
        return <div></div>
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])
}
