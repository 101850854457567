import { CcOrderPickingStatus } from "@/types/responses/buy"
import { sendKepsEvent } from "../keps"

export function sendOrderDetailsOpenEvent(
  eventTrigger: "button" | "card",
  orderNo: string | undefined,
  orderPickingStatus: CcOrderPickingStatus | undefined,
) {
  sendKepsEvent({
    event_location: "list",
    event_name: "order",
    event_detail: "order_details_opened",
    event_payload: JSON.stringify({
      order_number: orderNo,
      order_picking_status: orderPickingStatus,
      event_trigger_type: eventTrigger,
    }),
  })
}
