import { Trans, useTranslation } from "react-i18next"
import { FullServeStatus } from "./FullServeStatusText"
import classNames from "classnames"

interface OrderInformationProps {
  status?: FullServeStatus
  orderPaidDate?: string
  estimatedReadyTime?: number
  location?: "left" | "center"
  orderCard?: boolean
  isWaitingTimeEnabled: boolean
  isFastTrackEnabled?: boolean
}

export const OrderInformation: React.FC<OrderInformationProps> = ({
  status,
  orderPaidDate,
  estimatedReadyTime,
  location,
  orderCard,
  isWaitingTimeEnabled,
  isFastTrackEnabled,
}) => {
  const { t } = useTranslation()

  const pickupTime =
    orderPaidDate && estimatedReadyTime
      ? new Date(
          new Date(orderPaidDate).getTime() + estimatedReadyTime * 60 * 1000,
        )
      : undefined

  const pickupTimeInMinutes = pickupTime?.getMinutes()
  const formattedMinutes = pickupTimeInMinutes
    ? pickupTimeInMinutes < 10
      ? `0${pickupTimeInMinutes}`
      : `${pickupTimeInMinutes}`
    : undefined

  const informationText = (() => {
    switch (status) {
      case "WAITING_FOR_PAYMENT":
        return isFastTrackEnabled
          ? t("fullserve.scan-barcode-at-closest-payment-point")
          : `${t(orderCard ? "mobile.pay-at-payment-point" : "mobile.scan-to-pay")} ${t(
              isWaitingTimeEnabled && estimatedReadyTime
                ? "mobile.items-will-be-ready"
                : "mobile.start-preparing-order-bold",
              {
                estimatedWaitingTime: estimatedReadyTime,
              },
            )}`
      case "PREPARING_ORDER":
        return t(
          isWaitingTimeEnabled && estimatedReadyTime
            ? "mobile.estimated-collection-time"
            : "mobile.when-ready-collect-order",
        )
      case "READY_FOR_PICKUP":
        return t(
          orderCard
            ? "mobile.approach-pick-up-point-order-ready"
            : "mobile.collect-order",
        )
      case "COLLECTED":
        return orderCard ? t("mobile.thanks-for-shopping") : ""
      default:
        return t("mobile.checking-order-status")
    }
  })()

  return (
    <div
      className={classNames("flex flex-col", {
        "items-start": location === "left",
        "items-center text-center": location === "center",
      })}
    >
      <p className="text-sm">
        <Trans i18nKey={informationText} />
      </p>
      {status === "PREPARING_ORDER" && pickupTime && formattedMinutes && (
        <p className="text-blue-brand font-bold text-base">
          {pickupTime.getHours() + ":" + formattedMinutes}
        </p>
      )}
    </div>
  )
}
