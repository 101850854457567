import useChecklist from "@/hooks/useChecklist"
import {
  DepartmentProduct,
  isDepartmentArticle,
} from "@/types/product/categorizedProduct"
import { useMemo } from "react"
import AnimateHeight from "react-animate-height"
import { useTranslation } from "react-i18next"
import { TFunction } from "i18next"
import ListSection from "../list/ListSection"
import ProductCard from "../list/product-card/ProductCard"
import ChildItem from "../list/product-card/ChildItem"
import { isDDSKitchenArticle } from "@/types/product/listProduct"

type DepartmentSectionProps =
  | {
      type: "CONTACT_COWORKER" | "MARKET_HALL" | "SHOWROOM"
      products: DepartmentProduct[]
    }
  | {
      type: "FLOOR"
      products: DepartmentProduct[]
      floor: string
    }

const DepartmentSection: React.FC<DepartmentSectionProps> = (props) => {
  const { t } = useTranslation()
  const {
    checklist,
    toggleProductChecked,
    isAllChildrenChecked,
    unCheckChildren,
  } = useChecklist()

  const title = useMemo(() => {
    switch (props.type) {
      case "CONTACT_COWORKER":
        return t("common.contact-coworker")
      case "MARKET_HALL":
        return t("Interstitial-pickup-market-hall")
      case "SHOWROOM":
        return t("Interstitial-pickup-showroom")
      case "FLOOR":
        return t("common.level", { number: props.floor })
    }
  }, [props, t])

  return (
    <ListSection
      title={title}
      subTitle={t(
        props.products.length <= 1 ? "common.item" : "common.item_plural",
        {
          count: props.products.length,
        },
      )}
    >
      {props.products.map((product) => {
        return (
          <div
            key={product.info.no + product.isOrdered}
            className="mt-2 first:mt-0"
          >
            {isDepartmentArticle(product) ? (
              <ProductCard
                inlineMessageText={
                  isDDSKitchenArticle(product)
                    ? t("PIP-direct-delivery-modal-subtitle")
                    : undefined
                }
                inlineMessageVariant="cautionary"
                packages={product.info.packaging.packages}
                availability={product.stock.cashCarry}
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                type={product.locations[0]!.salesMethod}
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                department={product.locations[0]!.departmentName}
                checklist={{
                  isChecked: !!checklist[product.info.no],
                  onCheck: () =>
                    toggleProductChecked(
                      product.info.no,
                      product.quantity,
                      product.price,
                      product.locations.find(
                        (e) => e.salesMethod === "MARKET_HALL",
                      )
                        ? "markethall"
                        : "showroom",
                    ),
                }}
                product={product}
              />
            ) : (
              <div id={props.type + "_" + product.info.no}>
                <ProductCard
                  inlineMessageText={getPartiallyAvailableText(
                    props.type,
                    product,
                    t,
                  )}
                  inlineMessageVariant={
                    props.type === "CONTACT_COWORKER"
                      ? "cautionary"
                      : "informative"
                  }
                  availability={product.stock.cashCarry}
                  type={`${product.info.type}_PARENT`}
                  checklist={{
                    isChecked: isAllChildrenChecked(
                      product.info.no,
                      product.info.childItems.length,
                    ),
                    onCheck: () => unCheckChildren(product.info.no),
                  }}
                  product={product}
                />
                <AnimateHeight
                  duration={300}
                  height={
                    isAllChildrenChecked(
                      product.info.no,
                      product.info.childItems.length,
                    )
                      ? 0
                      : "auto"
                  }
                  disableDisplayNone
                >
                  {product.info.childItems.map((childItem) => (
                    <ChildItem
                      key={childItem.info.no}
                      product={childItem}
                      isSplit={product.info.isSplit}
                      checklist={{
                        isChecked:
                          !!checklist[
                            product.info.no + "." + childItem.info.no
                          ],
                        onCheck: () =>
                          toggleProductChecked(
                            product.info.no + "." + childItem.info.no,
                            childItem.quantity,
                            childItem.price,
                            childItem.locations.find(
                              (e) => e.salesMethod === "MARKET_HALL",
                            )
                              ? "markethall"
                              : "showroom",
                          ),
                      }}
                    />
                  ))}
                </AnimateHeight>
              </div>
            )}
          </div>
        )
      })}
    </ListSection>
  )
}

export default DepartmentSection

function getPartiallyAvailableText(
  type: "CONTACT_COWORKER" | "MARKET_HALL" | "SHOWROOM" | "FLOOR",
  product: DepartmentProduct,
  t: TFunction,
) {
  return "isSplit" in product.info && product.info.isSplit
    ? t(
        `Interstitial-ProductListCard-collect-self-serve-of-split-spr${
          type === "CONTACT_COWORKER"
            ? "-contact-coworker"
            : product.info.hasFullServePart
              ? ""
              : "-generic"
        }`,
        {
          count: product.info.totalChildItems,
        },
      )
    : undefined
}
