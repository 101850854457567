import { useQueryClient } from "@tanstack/react-query"
import { useListig } from "./useListig"
import {
  CashCarryOrderCreatedPartialResponse,
  CashCarryOrderResponse,
} from "@/types/responses/buy"
import { InstoreOrder } from "@/types/order"
import { parseCcOrder } from "@/utils/parseCcOrder"

export const useInstoreOrders = () => {
  const { list } = useListig()
  const queryClient = useQueryClient()

  const allOrders: InstoreOrder[] = []
  list?.orders?.forEach((listOrder) => {
    const order = queryClient.getQueryData<
      CashCarryOrderResponse | CashCarryOrderCreatedPartialResponse
    >(["order status", listOrder.orderNo])
    if (!order) {
      return
    }

    const instoreOrder = parseCcOrder(order)
    allOrders.push(instoreOrder)
  })

  return {
    orders: allOrders,
    activeUnpaidOrder: allOrders.find((order) => !order.isPaid),
  }
}
