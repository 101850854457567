import { ProductPrice } from "@/components/common/ProductPrice"
import { MAX_QUANTITY, MAX_QUANTITY_FULLSERVE } from "@/config/constants"
import { capitalize } from "@/config/locales"
import { useListig } from "@/hooks/useListig"
import { useLocale } from "@/hooks/useLocale"
import {
  CashCarryInformation,
  PackageMeasurements as PackageMeasurementsType,
  SalesMethod,
} from "@/types/responses/find"
import { dottedIdentifier } from "@/utils/dottedIdentifier"
import { getFeeText } from "@/utils/getFeeText"
import InlineMessage, { InlineMessageVariant } from "@ingka/inline-message"
import trashCan from "@ingka/ssr-icon/paths/trash-can"
import classNames from "classnames"
import AnimateHeight from "react-animate-height"
import { useTranslation } from "react-i18next"
import CheckboxCollect from "../CheckBoxCollect"
import ProductIdentifier from "@ingka/product-identifier"
import { SalesLocation } from "./SalesLocation"
import StockInformation from "./StockInformation"
import { ProductAvailableForDelivery } from "./ProductAvailableForDelivery"
import QuantityStepper from "@ingka/quantity-stepper"
import { useNotification } from "@/contexts/NotificationContext"
import { ListigList } from "@/types/listig"
import { sendItemDeletedUndoEvent } from "@/analytics/events/sendItemDeletedUndoEvent"
import { useLocalNavigation } from "@/hooks/useLocalNavigation"
import { useSelectQuantity } from "@/hooks/useSelectQuantity"
import { sendOpenPipEvent } from "@/analytics/events/sendOpenPipEvent"
import informationCircle from "@ingka/ssr-icon/paths/information-circle"
import warningTriangle from "@ingka/ssr-icon/paths/warning-triangle"
import { ProductLocation } from "@/components/pip/ProductLocation"
import Button from "@ingka/button"
import {
  isUPlanner,
  isUScoProduct,
  isDepartmentSalesLocation,
  isSelfServeSalesLocation,
} from "@/types/product/products"
import { ProductCardImage } from "./ProductCardImage"
import { ListProduct } from "@/types/product/listProduct"

const ProductCard: React.FC<ProductCardProps> = (props) => {
  const { market, language } = useLocale()
  const { t } = useTranslation()
  const notificationContext = useNotification()
  const { navigate } = useLocalNavigation()
  const productsQuery = useListig()
  const originalProducts = productsQuery.list?.items
  const waterLabel = props.product.media.images.find(
    (i) =>
      i.type === "WATER_SENSE_LABEL_IMAGE" ||
      i.type === "UNIFIED_WATER_LABEL_SYMBOL_IMAGE",
  )

  const isParent =
    props.type === "SPR_PARENT" ||
    props.type === "SCO_PARENT" ||
    props.type === "VPC_PARENT"

  const { selectedQuantity, setSelectedQuantity } = useSelectQuantity(
    props.product.info.no,
    props.product.info.type,
    props.product.quantity,
    "list",
  )

  const {
    deleteProduct: { delete: deleteProduct },
    addProducts: { add: addProduct },
  } = useListig()

  function handleUndoRemove() {
    const undoProduct: ListigList["items"] = [
      {
        id: props.product.info.no,
        type: props.product.info.type,
        quantity: props.product.quantity,
      },
    ]
    addProduct(undoProduct)
    notificationContext?.hide()

    sendItemDeletedUndoEvent(props.product.info.no)
  }

  function handleRemove(e: React.MouseEvent<HTMLDivElement>) {
    e.stopPropagation()
    deleteProduct(
      {
        productId: props.product.info.no,
        productType: props.product.info.type,
      },
      {
        onSuccess: () => {
          const notificationMessage = t("ShoppingList-product-removed", {
            product: props.product.info.name.toUpperCase(),
          })

          notificationContext?.show(notificationMessage, () => handleUndoRemove)
        },
      },
    )
  }

  function handleProductClick() {
    sendOpenPipEvent(props.product.info.no, "list")
    navigate({
      path: "product",
      productNo: props.product.info.no,
      productType: props.product.info.type,
    })
  }

  const productLocation =
    "locations" in props.product ? props.product.locations?.[0] : undefined

  const hidePrice =
    "isSplit" in props.product.info && props.product.info.isSplit

  const inlineMessageText =
    "inlineMessageText" in props ? props.inlineMessageText : undefined
  const inlineMessageVariant =
    "inlineMessageVariant" in props ? props.inlineMessageVariant : "informative"

  return (
    <div
      onClick={handleProductClick}
      className={classNames("flex flex-col bg-white px-6 pt-6", {
        // Rounded corners on cards that don't have children and are not Full Serve or Online
        "rounded-md":
          props.type !== "ONLINE" && props.type !== "FULL_SERVE" && !isParent,
        // Rounded corners on the parent that is not Full Serve or Online
        "rounded-t-md":
          isParent &&
          props.salesMethod !== "FULL_SERVE" &&
          props.salesMethod !== "ONLINE",
        "rounded-b-md": props.checklist?.isChecked && isParent,
      })}
    >
      <div className="flex flex-col mb-4" data-cy="product-card">
        <div className="flex flex-row gap-5">
          <ProductCardImage
            image={
              isUScoProduct(props.product)
                ? undefined
                : props.product.media.mainImage
            }
          />
          <div className="flex flex-col flex-1">
            {props.product.price && (
              <ProductPrice
                productName={
                  props.salesMethod === "FULL_SERVE" && props.product.isOrdered
                    ? props.product.quantity.toString() +
                      " X " +
                      props.product.info.name
                    : props.product.info.name
                }
                size="small"
                productDescription={`${isUPlanner(props.product) ? `${t("Planners.design-code")}: ` : ""}${capitalize(props.product.info.mediumDescription ?? props.product.info.description)}`}
                className={classNames("font-bold mr-0", {
                  hidden: hidePrice,
                })}
                prices={props.product.price}
                offers={props.product.offers}
                quantity={selectedQuantity}
                list
              />
            )}

            <div>
              {waterLabel && (
                <img
                  data-cy="water-label"
                  className="water-label my-2 h-6"
                  alt={waterLabel.alt}
                  src={waterLabel.href}
                />
              )}
              {props.product.media.energyLabelImage && (
                <img
                  className="energy-label my-2 h-6"
                  alt={props.product.media.energyLabelImage.alt}
                  src={props.product.media.energyLabelImage.href}
                  data-cy="energy-label"
                />
              )}
            </div>

            {!(props.salesMethod === "FULL_SERVE" && props.product.isOrdered) &&
              (props.type === "ONLINE" ? (
                <div
                  className={
                    props.product.media.energyLabelImage ? "pt-0" : "pt-3"
                  }
                >
                  <ProductAvailableForDelivery
                    isAvailableForDelivery={props.isAvailableForDelivery}
                  />
                </div>
              ) : (
                <StockInformation
                  product={
                    originalProducts?.find(
                      (prod) => prod.info.no === props.product.info.no,
                    ) ?? props.product
                  }
                  quantity={props.availability.quantity ?? 0}
                  probability={
                    props.availability.probability?.thisDay ?? "UNKNOWN"
                  }
                />
              ))}
          </div>
        </div>
        <div className="flex flex-col flex-1">
          <div className="flex flex-row w-full h-full">
            <div className="flex-1">
              <div className="flex flex-row justify-between">
                <div className="mb-0 whitespace-pre-wrap text-sm leading-relaxed text-gray-700 antialiased">
                  {props.product.price.fees && (
                    <div className="text-xs underline">
                      {props.product.price.fees[0] &&
                        market &&
                        language &&
                        getFeeText(
                          props.product.price.fees[0],
                          `${market.toLowerCase()}-${language.toUpperCase()}`,
                          true,
                        )}
                      <br />
                    </div>
                  )}
                </div>
              </div>

              <AnimateHeight
                duration={300}
                height={props.checklist?.isChecked ? 0 : "auto"}
                disableDisplayNone
              >
                <div className={classNames(`flex overflow-hidden flex-col`)}>
                  {!props.product.isOrdered && (
                    <div className="ml-2 mb-3 mt-4">
                      <div className="flex ml-24 gap-5">
                        <QuantityStepper
                          onClick={(e) => e.stopPropagation()}
                          readOnly={true}
                          data-cy="quantity-stepper"
                          small
                          value={selectedQuantity}
                          ariaDescribedById={
                            "quantity-stepper-" + props.product.info.no
                          }
                          ariaDescribedBy={t("aria.quantity-stepper")}
                          maxVal={
                            props.type === "FULL_SERVE"
                              ? MAX_QUANTITY_FULLSERVE
                              : MAX_QUANTITY
                          }
                          minVal={1}
                          onIncrease={(e: React.MouseEvent) => {
                            e.stopPropagation()
                            setSelectedQuantity(selectedQuantity + 1)
                          }}
                          onDecrease={(e: React.MouseEvent) => {
                            e.stopPropagation()
                            setSelectedQuantity(selectedQuantity - 1)
                          }}
                        />
                        <div onClick={handleRemove}>
                          <Button
                            className="px-1 py-1"
                            iconOnly
                            size="xsmall"
                            type={"tertiary"}
                            ssrIcon={trashCan}
                            data-cy="product-delete"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {inlineMessageText && (
                  <InlineMessage
                    data-cy="product-card-message"
                    variant={inlineMessageVariant}
                    ssrIcon={
                      inlineMessageVariant === "cautionary"
                        ? warningTriangle
                        : informationCircle
                    }
                    body={inlineMessageText}
                  />
                )}
                {(props.salesMethod !== "FULL_SERVE" || isParent) &&
                  props.type !== "ONLINE" && (
                    <hr className="divider demo-divider__hr-horizontal mt-3 mb-0" />
                  )}
              </AnimateHeight>

              <div>
                {!isParent &&
                  props.type !== "FULL_SERVE" &&
                  props.type !== "ONLINE" && (
                    <>
                      <div className="flex flex-row gap-4 my-3 w-full">
                        {!props.checklist?.isChecked && (
                          <>
                            <ProductIdentifier
                              label={t("common.article-number")}
                              data-cy="product-nr"
                              className="mt-0 text-sm"
                              value={dottedIdentifier(props.product.info.no)}
                            />
                            {(isDepartmentSalesLocation(productLocation) ||
                              isSelfServeSalesLocation(productLocation)) && (
                              <SalesLocation location={productLocation} />
                            )}
                          </>
                        )}
                        <div
                          className="flex flex-col justify-end ml-auto"
                          onClick={(e: React.MouseEvent<HTMLDivElement>) =>
                            e.stopPropagation()
                          }
                        >
                          {props.checklist && (
                            <CheckboxCollect
                              isChecked={props.checklist.isChecked}
                              onClick={props.checklist.onCheck}
                            />
                          )}
                        </div>
                      </div>
                      {props.packages && !props.checklist?.isChecked && (
                        <ProductLocation product={props.product} listView />
                      )}
                    </>
                  )}
                {props.checklist?.isChecked && isParent && (
                  <div
                    className="flex flex-row justify-end ml-auto"
                    onClick={(e: React.MouseEvent<HTMLDivElement>) =>
                      e.stopPropagation()
                    }
                  >
                    <CheckboxCollect
                      isChecked={props.checklist.isChecked}
                      onClick={props.checklist.onCheck}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {!(props.salesMethod === "FULL_SERVE" && props.product.isOrdered) &&
        (props.type === "FULL_SERVE" || props.type === "ONLINE" || isParent) &&
        !!props.partiallyAvailableWarning && (
          <InlineMessage
            className="mb-8"
            variant="informative"
            ssrIcon={informationCircle}
            body={props.partiallyAvailableWarning}
          />
        )}
    </div>
  )
}
export default ProductCard

export type ProductCardProps =
  | DepartmentProductCardProps
  | SelfServeProductCardProps
  | FullServeProductCardProps
  | OnlineProductCardProps
  | SprParentProductCardProps
  | ScoParentProductCardProps
  | PlannerParentProductCardProps

interface SprParentProductCardProps extends BaseProductCardProps {
  type: "SPR_PARENT"
  availability: CashCarryInformation
  inlineMessageText?: string
  inlineMessageVariant?: InlineMessageVariant
  partiallyAvailableWarning?: string
}

interface ScoParentProductCardProps extends BaseProductCardProps {
  type: "SCO_PARENT"
  availability: CashCarryInformation
  inlineMessageText?: string
  inlineMessageVariant?: InlineMessageVariant
  partiallyAvailableWarning?: string
}

interface PlannerParentProductCardProps extends BaseProductCardProps {
  type: "VPC_PARENT"
  availability: CashCarryInformation
  inlineMessageText?: string
  inlineMessageVariant?: InlineMessageVariant
  partiallyAvailableWarning?: string
}

interface DepartmentProductCardProps extends BaseProductCardProps {
  packages: PackageMeasurementsType[]
  availability: CashCarryInformation
  inlineMessageText?: string
  inlineMessageVariant?: InlineMessageVariant
  type: "MARKET_HALL" | "SHOWROOM"
  department: string
}
interface SelfServeProductCardProps extends BaseProductCardProps {
  packages: PackageMeasurementsType[]
  availability: CashCarryInformation
  type: "SELF_SERVE"
  aisle: string
  bin: string
}

interface FullServeProductCardProps extends BaseProductCardProps {
  availability: CashCarryInformation
  partiallyAvailableWarning?: string
  type: "FULL_SERVE"
}

interface OnlineProductCardProps extends BaseProductCardProps {
  isAvailableForDelivery: boolean
  partiallyAvailableWarning?: string
  type: "ONLINE"
}

interface BaseProductCardProps {
  packages?: PackageMeasurementsType[]
  type: SalesMethod | "SPR_PARENT" | "SCO_PARENT" | "VPC_PARENT"
  salesMethod?: SalesMethod
  checklist?: ChecklistProps
  product: ListProduct
}

export type ChecklistProps = {
  isChecked: boolean
  onCheck: () => void
}
