import {
  PermDiscountedPrice,
  PlannerProductInfo,
  Price,
  Prices,
  ScoProductInfo,
  SprProductInfo,
  TimeDiscountedPrice,
  UArticle,
  UChildProduct,
  UPlanner,
  USalesProduct,
  UScoProduct,
} from "../responses/find"
import {
  InStoreArticle,
  InStoreChildItem,
  InStoreProduct,
  OnlineArticle,
  OnlineChildItem,
} from "./categorizedProduct"
import { PipProduct } from "./pip"

export interface ListArticle extends UArticle {
  quantity: number
  isOrdered?: boolean
}

interface ListSprProductInfo extends SprProductInfo {
  childItems: ListChildItem[]
}

interface ListScoProductInfo extends ScoProductInfo {
  childItems: ListChildItem[]
}

interface ListPlannerProductInfo extends PlannerProductInfo {
  childItems: ListChildItem[]
}

export interface ListSalesProduct extends USalesProduct {
  info: ListSprProductInfo
  quantity: number
  isOrdered?: boolean
}

export interface ListScoProduct extends UScoProduct {
  info: ListScoProductInfo
  quantity: number
  isOrdered?: boolean
}

export interface ListPlannerProduct extends UPlanner {
  info: ListPlannerProductInfo
  quantity: number
  isOrdered?: boolean
}

export interface ListChildItem extends UChildProduct {
  price: Prices
  quantity: number
  isOrdered?: boolean
}

export type ListProduct =
  | ListArticle
  | ListChildItem
  | ListSalesProduct
  | ListScoProduct
  | ListPlannerProduct

export function isArticle(
  product: ListProduct,
): product is ListArticle | ListChildItem {
  return product.info.type === "ART"
}

export function isSalesProduct(
  product: ListProduct,
): product is ListSalesProduct {
  return product.info.type === "SPR"
}

export function isDDSKitchenArticle(article: PipProduct): boolean {
  return !!article.disabledRange?.isDirectDelivery
}

export function isInStockArticle(
  product: InStoreArticle | OnlineArticle | InStoreChildItem | OnlineChildItem,
): product is InStoreArticle {
  return (
    isDDSKitchenArticle(product) ||
    (product.stock.cashCarry.inRange &&
      !!product.stock.cashCarry.quantity &&
      product.stock.cashCarry.quantity >= product.quantity &&
      product.stock.cashCarry.probability?.thisDay !== "OUT_OF_STOCK" &&
      (product.locations?.[0]?.salesMethod !== "FULL_SERVE" ||
        !product.disabledRange?.isOnlineOnly))
  )
}

export const isInStock = (product: ListProduct): product is InStoreProduct =>
  (isArticle(product) && isInStockArticle(product)) ||
  (!isArticle(product) && product.stock.cashCarry.inRange)

export function isDiscountedPrice(
  price: Price,
): price is TimeDiscountedPrice | PermDiscountedPrice {
  return "reason" in price && !!price.reason
}
