import { PipProduct } from "@/types/product/pip"
import { isDDSKitchenArticle } from "@/types/product/listProduct"
import { isNotProductError } from "@/types/product/products"
import { StockProbability } from "@/types/responses/find"
import { isEnoughInStock } from "@/utils/getSalesLocation"
import Status from "@ingka/status"
import { TFunction } from "i18next"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"

interface StockInformationProps {
  product: PipProduct
  quantity: number
  probability: StockProbability
}

const StockInformation: React.FC<StockInformationProps> = (props) => {
  const { t } = useTranslation()

  const label: string = useMemo((): string => {
    const text = getAvailabilityLabel(t, props)

    return props.quantity > 100
      ? text.replace(props.quantity.toString(), "100+")
      : props.probability === "LOW_IN_STOCK"
        ? t("AvailabilityCard-few-in-store")
        : text
  }, [props, t])

  const statusColor: StatusColor = useMemo((): StatusColor => {
    if (isDDSKitchenArticle(props.product)) return "green"

    if (!props.product.stock.cashCarry.inRange) {
      return "grey"
    }

    switch (props.probability) {
      case "HIGH_IN_STOCK":
      case "MEDIUM_IN_STOCK":
        return "green"
      case "LOW_IN_STOCK":
        return "orange"
      default:
        return "red"
    }
  }, [props.product, props.probability])

  return (
    <Status label={label} statusColor={statusColor} className="align-middle" />
  )
}

export default StockInformation

type StatusColor = "green" | "orange" | "red" | "grey"

function getAvailabilityLabel(t: TFunction, props: StockInformationProps) {
  if (isDDSKitchenArticle(props.product)) return t("common.contact-coworker")

  if (props.product.info.type === "SPR" || props.product.info.type === "SCO") {
    const noOfChildProductsInStock = props.product.info.childItems?.filter(
      (item) => isNotProductError(item) && isEnoughInStock(item),
    ).length

    if (noOfChildProductsInStock !== props.product.info.childItems?.length) {
      const childItemsAvailabilityCopy = t(
        "availability.item-partly-in-stock",
        {
          count: noOfChildProductsInStock,
          total: (props.product.info.childItems?.length || 0).toString(),
        },
      )

      return childItemsAvailabilityCopy
    }
  }

  if (!props.product.stock.cashCarry.inRange) {
    return t("AvailabilityCard-not-sold-here")
  }

  //TODO - fix with low vs high stock
  return t("availability.count-in-stock", {
    count: props.quantity,
  })
}
