import SSRIcon from "@ingka/ssr-icon"
import { JSX, ReactNode } from "react"

interface HeaderProps {
  icon?: JSX.Element
  onIconClick?: () => void
  title: string
  subTitle?: ReactNode
  children?: ReactNode
  className?: string
}

const Header: React.FC<HeaderProps> = (props) => {
  const { icon } = props

  return (
    <div className={`top-0 ${props.className || ""}`}>
      <div className="flex w-full justify-between items-center p-5 px-7 bg-gray-100">
        <div className="flex flex-col">
          <h5 className="text-base leading-normal font-semibold">
            {props.title}
          </h5>
          {props.subTitle}
        </div>

        {icon && (
          <div
            data-cy="section-header-button"
            className="transform"
            onClick={props.onIconClick}
          >
            {typeof icon === "function" ? <SSRIcon paths={icon} /> : icon}
          </div>
        )}
      </div>
      {props.children}
    </div>
  )
}

export default Header
