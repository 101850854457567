import { Query, useQueryClient } from "@tanstack/react-query"
import { useQueryWithErrorHandling } from "./useReactQuery"
import { getOAuthToken, getUpptackaJWT, OAuthToken } from "@/api/getOAuthToken"

/**
 * Sets up OAuth token which will be used for all our API calls
 */
export function useAuth() {
  const { data: oAuthToken, error: oAuthError } = useQueryWithErrorHandling(
    ["token", "oauth"],
    () => getOAuthToken(),
    {
      refetchOnWindowFocus: true,
      refetchInterval: tokenExpiryTime,
      staleTime: tokenExpiryTime,
      retry: 3,
    },
  )

  return {
    oAuthToken:
      oAuthToken && oAuthToken.expiry > Date.now()
        ? oAuthToken.token
        : undefined,
    oAuthError,
  }
}

/**
 * Returns an async function to fetch auth token
 */
export const useAuthAsync = () => {
  const queryClient = useQueryClient()

  return {
    getAuthToken: async () =>
      (
        await queryClient.fetchQuery({
          queryKey: ["token", "oauth"],
          queryFn: () => getOAuthToken(),
          staleTime: tokenExpiryTime,
          retry: 3,
        })
      ).token,
  }
}

/**
 * Returns an async function to fetch upptäcka token
 */
export const useUpptackaTokenAsync = () => {
  const queryClient = useQueryClient()

  return {
    getUpptackaToken: async () =>
      queryClient.fetchQuery({
        queryKey: ["token", "upptacka"],
        queryFn: () => getUpptackaJWT(),
      }),
  }
}

const tokenExpiryTime: (
  query: Query<OAuthToken, Error, OAuthToken, string[]>,
) => number = (query) => {
  const token = query.state.data
  return token ? token.expiry - Date.now() - 10000 : Infinity
}
