import { addOrder } from "@/api/listig"
import { useAuthAsync } from "@/hooks/useAuth"
import { useMutationWithErrorHandling } from "./useReactQuery"
import { useQueryClient } from "@tanstack/react-query"
import { useCallback } from "react"
import { ListigItem, ListigList } from "@/types/listig"

/**
 * Provides a function to add an order to the list,
 * will automatically refetch the list afterwards.
 * @returns a function to add an order to listig list
 */
export function useAddOrder() {
  const { getAuthToken } = useAuthAsync()
  const queryClient = useQueryClient()

  /** mutation to add an order to listig and refresh on success */
  const mutation = useMutationWithErrorHandling(
    async (input: {
      listId: string | null
      orderNo: string | null
      orderNoSource: string | null
      items: ListigItem[] | null
      removeItemsFromList: boolean
    }) => {
      const oAuthToken = await getAuthToken()

      return input.listId &&
        input.orderNo &&
        input.orderNoSource &&
        input.items &&
        oAuthToken
        ? addOrder(
            input.listId,
            input.orderNo,
            input.orderNoSource,
            input.items,
            input.removeItemsFromList,
            oAuthToken,
          )
        : Promise.reject(new Error("Missing fields"))
    },
    {
      onSuccess: async (list: ListigList) => {
        // Cancel any outgoing refetches
        await queryClient.cancelQueries({
          queryKey: ["listig", list.id.toString()],
        })
        queryClient.invalidateQueries({
          queryKey: ["listig", list.id.toString()],
        })
      },
    },
  )

  const mutate = mutation.mutate
  const addListigOrder = useCallback(
    (
      listId: string | null,
      orderNo: string | null,
      orderNoSource: string | null,
      items: ListigItem[] | null,
      removeItemsFromList: boolean,
      options?: {
        onSuccess?: () => void
      },
    ) =>
      mutate(
        { listId, orderNo, orderNoSource, items, removeItemsFromList },
        { onSuccess: options?.onSuccess },
      ),
    [mutate],
  )

  return {
    /** add an order to list and automatically reload list */
    add: addListigOrder,
    /** error when adding order */
    error: mutation.error,
  }
}
